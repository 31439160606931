import './assets/vendor/css/google_fonts.css';
import './plugins/bootstrap-vue';
import './plugins/vue-browserupdate';
import './prototypes/Tools.js';
import '@babel/polyfill';
// import './assets/vendor/css/fontawesome_5_11_2/css/all.css';
import 'mutationobserver-shim';

import Vue from 'vue';

import App from './App.vue';
import AnimateOnScroll from './mixins/AnimateOnScroll';
import Api from './mixins/Api';
import Events from './mixins/Events';
import Listeners from './mixins/Listeners';
import Messages from './mixins/Messages';
import router from './router';
import store from './store';

Vue.config.productionTip = true;

document.head.querySelector("link[rel=icon]").href =
  document.location.origin + "/favicon.ico";

new Vue({
  mixins: [Listeners, Events, Messages, Api, AnimateOnScroll],
  store,
  router,
  render: (h) => h(App),
  methods: {},
}).$mount("#app");

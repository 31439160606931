import Vue from 'vue';

let timer1 = null;
let timer2 = null;

const _tools = {
  handleSmooth(id) {
    const smoothSlide = document.querySelector(id);
    if (smoothSlide) {
      smoothSlide.scrollIntoView({
        behavior: "smooth",
      });
      clearTimeout(timer1);
      timer1 = setTimeout(() => {
        smoothSlide.scrollIntoView({
          behavior: "auto",
        });
      }, 1000);
      clearTimeout(timer2);
      timer2 = setTimeout(() => {
        smoothSlide.scrollIntoView({
          behavior: "auto",
        });
      }, 2000);
    }
  },
  showLimitedString(string = "", limit = 1) {
    if (limit >= string.length) {
      limit = string.length;
    }
    let limitation = limit < string.length;

    let text = "";
    for (let i = 0; i < limit; i++) {
      text += i === 0 ? string[i].toUpperCase() : string[i];
    }
    return text + (limitation ? "..." : "");
  },
  getRandomInt(max = 32000) {
    return Math.floor(Math.random() * Math.floor(max));
  },
  getRandomStr(size = 32, letters = "azertyuiopqsdfghjklmwxcvbn") {
    let string = "";
    for (let i = 0; i < size; i++) {
      string += letters[this.getRandomInt(letters.length - 1)];
    }
    return string;
  },
  canUseWebP() {
    var elem = document.createElement("canvas");
    if (!!(elem.getContext && elem.getContext("2d"))) {
      // was able or not to get WebP representation
      return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
    }
    // very old browser like IE 8, canvas not supported
    return false;
  },
  formatter_lowercase(value) {
    if (typeof value !== "string") return "";
    return value.toLowerCase();
  },
  formatter_uppercase(value) {
    if (typeof value !== "string") return "";
    return value.toUpperCase();
  },
  formatter_capitalize(value) {
    if (typeof value !== "string") return "";
    return value.charAt(0).toUpperCase() + value.slice(1);
  },
};
export default _tools;
Vue.prototype.$tools = _tools;

import Tools from '@/prototypes/Tools';

export default {
  namespaced: true,
  state: () => ({
    wordsCarouselMain: [
      {
        classes: "",
        text: "Bienvenue sur ma WebApp",
        duration: 3000,
      },
      {
        classes: "",
        text: "Je m'appelle Julien JEAN",
        duration: 2533,
      },
      {
        classes: "",
        text: "Je suis développeur web",
        duration: 1833,
      },
      {
        classes: "",
        text: "J'ai mis en pratique mes connaissances",
        duration: 1833,
      },
      {
        classes: "",
        text: "pour réaliser cette WebApp avec Vue.JS",
        duration: 1833,
      },
      {
        classes: "",
        text: "En vous souhaitant Une bonne navigation & UX",
        duration: 1833,
      },
    ],
    wideAsideCarousel: ["Julien JEAN", "39 ans", "Nice"],
    wideScreenWordsCarouselExtends: [
      {
        classes: "font_bungee html5",
        text: "> l'API HTML5 et JS pour le rendu",
        duration: 2000,
      },
      {
        classes: "font_bungee php",
        text: "> PHP7 Orienté Objet",
        duration: 1200,
      },
      {
        classes: "font_bungee vuejs",
        text: "> Vue.JS / CLI / NuxtJS",
        duration: 1500,
      },
      {
        classes: "font_bungee bootstrap",
        text: "> Bootstrap 4.5 pour Vue.JS",
        duration: 1200,
      },
      {
        classes: "font_bungee node-js",
        text: "> Framework Express.JS [TWIG]",
        duration: 1500,
      },
      {
        classes: "font_bungee text-warning",
        text: "> MySQL [MCD,MLD]",
        duration: 1200,
      },
      {
        classes: "font_bungee",
        text: "Je travaille en methode Agile",
        duration: 1200,
      },
      {
        classes: "font_bungee",
        text: "Je travaille avec GIT [gitlab]",
        duration: 1200,
      },
      {
        classes: "font_bungee btn btn-outline-warning",
        text: "Je recherche un emploi autour de Nice",
        duration: 6000,
        link: "https://api.naskot.fr/api.php/go/to/url?qr=53381a3cdccbfb8fe60b4149b20aef2e",
      },
    ],
    smallScreenWordsCarouselExtends: [
      {
        classes: "badge-pill m-2 py-3 bg-light text-dark",
        text: "Mes compètences / Développement",
        duration: 5000,
      },
      {
        classes: "badge-pill m-2 py-3 bg-transparent text-warning",
        text: "l'API HTML5 et Javascript pour le rendu",
        duration: 2000,
      },
      {
        classes: "badge-pill m-2 py-3 bg-transparent text-warning",
        text: "PHP7 Orienté Objet",
        duration: 1200,
      },
      {
        classes: "badge-pill m-2 py-3 bg-transparent text-warning",
        text: "Vue.JS / CLI / NuxtJS",
        duration: 1500,
      },
      {
        classes: "badge-pill m-2 py-3 bg-transparent text-warning",
        text: "Bootstrap 4.5 pour Vue.JS",
        duration: 1200,
      },
      {
        classes: "badge-pill m-2 py-3 bg-transparent text-warning",
        text: "Framework Express.JS [TWIG]",
        duration: 1500,
      },
      {
        classes: "badge-pill m-2 py-3 bg-transparent text-warning",
        text: "MySQL [MCD,MLD]",
        duration: 1200,
      },
      {
        classes: "badge-pill m-2 py-3 bg-transparent text-warning",
        text: "Je travaille en methode Agile",
        duration: 1200,
      },
      {
        classes: "badge-pill m-2 py-3 bg-transparent text-warning",
        text: "Je travaille avec GIT [gitlab]",
        duration: 1200,
      },
      {
        classes: "badge-pill m-2 py-3 bg-warning text-dark",
        text: "Je recherche un emploi autour de Nice",
        duration: 6000,
      },
    ],
  }),
  getters: {
    identity() {
      if (Tools.canUseWebP()) {
        return require("@/assets/webp/home/identity.webp");
      } else {
        return require("@/assets/img/home/identity.jpg");
      }
    },
  },
  mutations: {},
  actions: {},
};

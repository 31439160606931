export default {
  namespaced: true,
  state: () => ({
    // isBusy
    startBusy: true,
    isHTTPBusy: false,
    isHTTPReading: false,

    // Axios
    contentLength: 0,
    firstNetworkError: false,
    perm_exec_time: "",
    debug: "--debug",
  }),
  getters: {},
  mutations: {
    // isBusy
    start_busy(state, payload) {
      state.startBusy = payload;
    },
    http_busy(state, payload) {
      state.isHTTPBusy = payload;
    },
    http_reading(state, payload) {
      state.isHTTPReading = payload;
    },

    // Axios
    content_length(state, payload) {
      state.contentLength = payload;
    },
    first_network_error(state, payload) {
      state.firstNetworkError = payload;
    },
    perm_exec_time(state, payload) {
      state.perm_exec_time = payload;
    },
    console_debug(state, payload) {
      state.debug = payload;
    },
  },
  actions: {
    // isBusy
    start_busy({ commit }, action = false) {
      commit("start_busy", action ? true : false);
    },
    http_busy({ commit }, action = false) {
      commit("http_busy", action ? true : false);
    },
    http_reading({ commit }, action = false) {
      commit("http_reading", action ? true : false);
    },

    // Axios
    content_length({ commit }, value = 0) {
      commit("content_length", parseInt(value));
    },
    first_network_error({ commit }, action = false) {
      commit("first_network_error", action ? true : false);
    },
    perm_exec_time({ commit }, result = "") {
      commit("perm_exec_time", result);
    },
    console_debug({ commit }, result = "") {
      commit("console_debug", result);
    },
    async renewCaptcha({ dispatch }, { vm }) {
      dispatch("http_busy", true);

      await vm.$root.axios("put", "api/captcha/change");
    },
  },
};

export default {
  namespaced: true,
  state: () => ({
    bvToast: [],
  }),
  getters: {},
  mutations: {
    bvToast(state, payload) {
      state.bvToast = payload;
    },
  },
  actions: {
    bvToast({ commit }, { title, msg, variant, time, timeout }) {
      commit("bvToast", [
        {
          title: title || "",
          msg: msg || "",
          variant: variant || "primary",
          time: time || 2000,
          timeout: timeout || 0,
        },
      ]);
    },
  },
};

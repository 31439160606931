import { mapState } from 'vuex';

export default {
  data() {
    return {
      timer_state: null,
    };
  },
  computed: {
    ...mapState(["events"]),
  },
  mounted() {
    window.addEventListener("resize", this.handleWindow);
    window.addEventListener("scroll", this.handleWindow);
    window.dispatchEvent(new Event("resize"));
    window.dispatchEvent(new Event("scroll"));
  },
  destroyed() {
    window.removeEventListener("resize", this.handleWindow);
    window.removeEventListener("scroll", this.handleWindow);
  },
  methods: {
    handleWindow() {
      this.$store.dispatch("events/handleWindow", window);
    },
    timer_loop() {
      clearTimeout(this.timer_state);
      this.timer_state = setTimeout(() => {
        if (!this.$root.isUserLoggedIn()) {
          this.$router.push("/account/login");
        }
        this.timer_loop();
      }, 1000);
    },
    changeTab(tab) {
      if (
        this.$route.params.hasOwnProperty("tab") &&
        this.$route.params.tab !== tab
      ) {
        this.$router.push({ path: tab });
      }
    },
  },
};

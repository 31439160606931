export default {
  namespaced: true,
  state: () => ({
    qrcode: {
      cv:
        "https://api.naskot.fr/api.php/go/to/url?qr=202cb962ac59075b964b07152d234b70",
      _cv: "http://personnal.naskot.fr/pancarte_cv_horizontal/",
    },
  }),
  getters: {},
  mutations: {},
  actions: {},
};

import md5 from 'md5';
import { mapState } from 'vuex';

import Axios from '@/mixins/Axios';

const api_data = {
  mixins: [Axios],
  data() {
    return {
      timer_state: null,
      timer_pause: false,
      timer: 5000,
      get_urn_request_increment: [],
      get_urn_args_increment: {},
    };
  },
  mounted() {
    this.renewToken();
  },
  beforeDestroy() {
    clearTimeout(this.timer_state);
    this.$store.dispatch("account/user_account", {});
    this.$store.dispatch("account/extra_info", {});
    this.$store.dispatch("account/axios_data", {});
  },
  computed: {
    ...mapState(["account"]),
  },
  methods: {
    loopGetRequest() {
      clearTimeout(this.timer_state);
      this.timer_state = setTimeout(() => {
        if (!this.timer_pause) this.processGetRequest();
        else this.timer_pause = false;
        this.loopGetRequest();
      }, parseInt(this.timer));
    },
    async processGetRequest() {
      const res = this.axios(
        "get",
        this.get_urn_request_increment.join("/"),
        this.get_urn_args_increment,
        false
      ); // true pour economie bande passante
      if (window.location.hostname === "127.0.0.1") {
        console.info(
          "URN Request " +
            this.get_urn_request_increment.length +
            " " +
            JSON.stringify(this.get_urn_request_increment)
        );
      }

      this.get_urn_request_increment = [];
      return res;
    },
    async handleGetRequest(request = "/", exec_request = false, args = null) {
      if (this.get_urn_request_increment.indexOf(request) === -1) {
        this.get_urn_request_increment.push(request);
        if (args) {
          this.get_urn_args_increment = args;
        }
      }
      if (exec_request) {
        this.timer_pause = true;
        return await this.processGetRequest();
      }
    },
    async axios(method, url = "", params = {}, compareVersion = false) {
      let response;
      switch (method) {
        case "get":
          response = await this.getData(url, params, compareVersion);
          break;
        case "post":
          response = await this.postData(url, params);
          this.timer_pause = true;
          break;
        case "put":
          response = await this.changeData(url, params);
          this.timer_pause = true;
          break;
        case "delete":
          response = await this.deleteData(url, params);
          this.timer_pause = true;
          break;
      }
      return response;
    },
    axiosData(axios_data) {
      // this.account.axios_data = axios_data;
      this.$store.dispatch("account/axios_data", axios_data);

      if (this.account.axios_data["DEBUG"]) {
        this.$bvToast.toast(this.account.axios_data["DEBUG"], {
          title: "Debug",
          variant: "info",
          solid: true,
          autoHideDelay: 30000,
          appendToast: true,
          toaster: "b-toaster-bottom-right",
        });
      }

      if (this.account.axios_data["JWT"]) {
        this.setToken(this.account.axios_data["JWT"]);
      }

      if (this.account.axios_data["hash_version"]) {
        localStorage.setItem(
          "hash_version",
          this.account.axios_data["hash_version"]
        );
      }

      if (this.account.axios_data["content"]) {
        this.setContent(this.account.axios_data["content"]);
      }

      if (this.account.axios_data["extra_user_info"]) {
        this.setExtraInfo(this.account.axios_data["extra_user_info"]);
      }

      if (this.account.axios_data["auth_response"]) {
        switch (this.account.axios_data["auth_response"]) {
          case "not_connected":
            window.location.href = "/";
            break;
        }
      }
      if (this.account.axios_data && this.account.axios_data.check_input) {
        this.show_toast_check_input(this.account.axios_data.check_input);
      }

      if (this.account.axios_data["perf_result_milliseconds"]) {
        this.$store.dispatch("axios/perm_exec_time",
          this.account.axios_data["perf_result_milliseconds"].string_type
        );
      }
    },
    getUserInfo() {
      const user = this.account.user;

      return {
        loggedIn: user.hasOwnProperty("loggedIn") ? user.loggedIn : false,
        rank: user.hasOwnProperty("rank") ? user.rank : "guest",
        username: user.hasOwnProperty("username") ? user.username : "n/a",
        firstName: user.hasOwnProperty("firstName") ? user.firstName : "",
        lastName: user.hasOwnProperty("lastName") ? user.lastName : "",
        email: user.hasOwnProperty("email") ? user.email : "-@-.-",
      };
    },
    getToken() {
      const jwt = localStorage.getItem("jwt");
      return jwt === null ? "" : jwt;
    },
    async renewToken() {
      await this.handleGetRequest("api/token/get", true);
    },
    clearStorage() {
      localStorage.clear();
    },
    async setToken(jwt = "") {
      if (jwt === "") {
        await this.renewToken();
        this.showToast(`Erreur`, "Token invalide", "danger", 10000, 1500);
        window.location.href = "/";
        clearTimeout(this.__timer_state);
      }

      localStorage.setItem("jwt", jwt);
    },
    getContent() {
      const content = this.account.user;
      return content === null
        ? {
            id: -1,
            loggedIn: false,
            username: "",
            rank: "guest",
            firstName: "",
            lastName: "",
            email: "",
            created: "01-01-1970 00:00:00",
            last_connected: "01-01-1970 00:00:00",
            captchaPassed: false,
          }
        : content;
    },
    setContent(content) {
      this.$store.dispatch("account/user_account", content);
      // this.account.user = content;
      this.timer =
        this.account.user && this.account.user.loggedIn ? 1000 : 10000; // TIMER A 1000
      this.loopGetRequest();
      const string_content = JSON.stringify(content);
      localStorage.setItem("user", string_content);
    },
    isContentChanged(content) {
      return md5(JSON.stringify(content)) === md5(localStorage.getItem("user"));
    },
    getExtraInfo() {
      const extraInfo = this.account.extraInfo;
      return extraInfo === null
        ? {
            gender: "male",
            firstName: "",
            lastName: "",
            phone: "",
            age: 0,
            adress: "",
            citycode: "00000",
            city: "",
          }
        : extraInfo;
    },
    setExtraInfo(extraInfo) {
      this.$store.dispatch("account/extra_info", extraInfo);
      // this.account.extraInfo = extraInfo;
      const string_extraInfo = JSON.stringify(extraInfo);
      localStorage.setItem("extra_info", string_extraInfo);
    },
    isExtraInfoChanged(extraInfo) {
      return (
        md5(JSON.stringify(extraInfo)) ===
        md5(localStorage.getItem("extra_info"))
      );
    },
    isUserLoggedIn() {
      return this.account.user && this.account.user.loggedIn ? true : false;
    },
  },
};

export default api_data;

import Vue from 'vue';

import VueRouter from 'vue-router';

import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes.entries,
  scrollBehavior(to, from, savedPosition) {
    setTimeout(() => {
      const smoothSlide = document.querySelector("#app");
      if (smoothSlide) {
        smoothSlide.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 150);
  },
});

export default router;

export default {
  namespaced: true,
  state: () => ({
    width: 0,
    height: 0,
    scrollX: 0,
    scrollY: 0,
  }),
  getters: {
    handleMinHeight(state) {
      const height = state.height - 56;
      return {
        "min-height": parseInt(height) + "px",
      };
    },
  },
  mutations: {
    width(state, payload) {
      state.width = payload;
    },
    height(state, payload) {
      state.height = payload;
    },
    scrollX(state, payload) {
      state.scrollX = payload;
    },
    scrollY(state, payload) {
      state.scrollY = payload;
    },
  },
  actions: {
    handleWindow({ commit }, window) {
      commit("width", window.innerWidth);
      commit("height", window.innerHeight);

      const docElement = document.documentElement;

      const scrollX =
        (window.scrollX / (docElement.scrollWidth - docElement.clientWidth)) *
        100;
      const scrollY =
        (window.scrollY / (docElement.scrollHeight - docElement.clientHeight)) *
        100;

      commit("scrollX", parseInt(isNaN(scrollX) ? 0 : scrollX));
      commit("scrollY", parseInt(isNaN(scrollY) ? 0 : scrollY));
    },
  },
};

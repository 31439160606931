const routes = {
  entries: [
    {
      path: '*',
      name: '404',
      icon: '',
      component: () => import('@/components/View404'),
      meta: {
        requiresAuth: false,
        access: ['guest', 'user', 'operator', 'admin', 'root'],
        title: '404 not found',
        keywords: '404,  erreur',
        description: 'Aucune information sur cette page',
      },
    },
  ],
  dontShowPathToNavbar: [
    '*',
    '/sitemap',
    '/cv',
    '/terms-of-use',
    // "/account/login",
    '/account/register',
    '/account/recover',
    '/account/:tab',
    '/admin/:tab',
    // "/logout",
  ],
  privateMenu: [
    '/account/login',
    '/account/register',
    // "/account/recover",
    '/account/:tab',
    '/admin/:tab',
    '/logout',
    '/admin',
  ],

  addDynamicRoutes(entries) {
    entries.forEach((entry) => {
      entry['beforeEnter'] = (to, from, next) => {
        document.title = to.meta.title
        document.head.querySelector('meta[name=description]').content =
          to.meta.description
        document.head.querySelector('meta[name=keywords]').content =
          to.meta.keywords
        document.head.querySelector('link[rel=canonical]').href =
          document.location.origin + to.path
        next()
        this.authCondition(to, from, next)
      }
      this.entries.push(entry)
    })
  },

  authCondition(to, from, next) {
    const user = JSON.parse(localStorage.getItem('user'))
    const meta = to.meta
    const requiresAuth = meta.hasOwnProperty('requiresAuth')
      ? meta.requiresAuth
      : true
    if (user) {
      const loggedIn = user.hasOwnProperty('loggedIn') ? user.loggedIn : false

      if (loggedIn) {
        switch (to.path) {
          case '/account/login':
            next({ path: '/account/dashboard' })
            break
          case '/account/register':
            next({ path: '/account/dashboard' })
            break
          case '/account/recover':
            next({ path: '/account/dashboard' })
            break
        }
      }

      if (!loggedIn && requiresAuth) {
        next({ path: '/account/login' })
      }
    } else if (!user && requiresAuth) {
      next({ path: '/account/login' })
    }
  },
  canShowThisRoute(path = null, showPrivateMenu = false) {
    if (showPrivateMenu) {
      return this.privateMenu.indexOf(path) !== -1 ? true : false
    } else {
      if (this.dontShowPathToNavbar.indexOf(path) !== -1) return false
      return this.privateMenu.indexOf(path) === -1 ? true : false
    }
  },

  showEveryoneExcept404(path = null) {
    if (this.dontShowPathToNavbar.indexOf(path) !== -1) return false
    return true
  },
}

// Add routes after ...
routes.addDynamicRoutes([
  {
    path: '/',
    name: 'Bienvenue',
    icon: 'fas fa-home',
    component: () => import('@/views/Home'),
    meta: {
      requiresAuth: false,
      access: ['guest', 'user', 'operator', 'admin', 'root'],
      title:
        "Carousel d'images dynamiques présentant la ville de Nice,  différents lieux",
      keywords:
        'Julien,  Paul,  Joël,  JEAN,  Nice,  développeur,  38,  ans,  38ans,  carousel,  image,  vuejs,  vue,  naskot,  naskotrp,  roleplay',
      description:
        'Portfolio Vue.js de Julien JEAN, développeur web PHP/JavaScript sur Nice',
    },
  },

  {
    path: '/more-info',
    name: 'En savoir plus',
    icon: 'fas fa-info-circle',
    component: () => import('@/views/MoreInfo'),
    meta: {
      requiresAuth: false,
      access: ['guest', 'user', 'operator', 'admin', 'root'],
      title: 'En savoir plus sur moi',

      keywords:
        'Julien,  Paul,  Joël,  JEAN,  Nice,  développeur,  38,  ans,  38ans,  en savoir plus,  réalisation,  vuejs,  vue,  naskot,  naskotrp,  roleplay',
      description: 'Présentation personnelle',
    },
  },

  {
    path: '/draggable-container',
    name: 'Démo DragContainer',
    icon: 'fas fa-palette',
    component: () => import('@/views/DraggableContainer'),
    meta: {
      requiresAuth: false,
      access: ['guest', 'user', 'operator', 'admin', 'root'],
      title: 'Démo Draggable Container Javascript',
      keywords: 'vuejs,  vue,  naskot,  drag, draggable, container, javascript',
      description:
        'Démo Draggable Container, une Class de gestion de container déplacable',
    },
  },

  {
    path: '/money-games',
    name: 'Démo Machine à sous',
    icon: 'fas fa-gamepad',
    component: () => import('@/views/Games'),
    meta: {
      requiresAuth: false,
      access: ['guest', 'user', 'operator', 'admin', 'root'],
      title:
        "Démo Machine à sous réalisé à l'aide du Javascript,  machine à sous",
      keywords:
        'naskot,  naskotrp,  roleplay,  machine à sous,  machine,  sous',
      description:
        "Démo Machine à sous. Réalisation Javascript, réalisé sous forme de composant VueJS,  réutilisable,  j'ai réalisé une machiné à sous provenant d'un ancien projet GTA5 Naskot roleplay,  je vous présenterais d'autres script",
    },
  },

  {
    path: '/my-folio',
    name: 'Portfolio',
    icon: 'fas fa-chalkboard-teacher',
    component: () => import('@/views/PortFolio'),
    meta: {
      requiresAuth: false,
      access: ['guest', 'user', 'operator', 'admin', 'root'],
      title: 'Voici mon Portfolio',
      keywords:
        'Julien,  Paul,  Joël,  JEAN,  Nice,  développeur,  38,  ans,  38ans,  en savoir plus,  réalisation,  vuejs,  vue,  naskot,  naskotrp,  roleplay',
      description: 'Voici mon portfolio, je présente quelques projets réalisés',
    },
  },

  {
    path: '/account/login',
    name: 'Connexion',
    icon: 'fas fa-sign-in-alt',
    component: () => import('@/views/Login'),
    meta: {
      requiresAuth: false,
      access: ['guest'],
      title: 'Connexion',
      keywords: 'Espace , membres',
      description: 'Espace membres',
    },
  },
  {
    path: '/account/register',
    name: 'Inscription',
    icon: 'fas fa-plus',
    component: () => import('@/views/Register'),
    meta: {
      requiresAuth: false,
      access: ['guest'],
      title: 'Inscription',
      keywords: 'Espace , membres',
      description: 'Espace membres',
    },
  },
  {
    path: '/account/recover',
    name: 'Mot de passe oublié',
    icon: 'fas fa-key',
    component: () => import('@/views/PasswordForgot'),
    meta: {
      requiresAuth: false,
      access: ['guest'],
      title: 'Mot de passe oublié',
      keywords: 'Espace , membres',
      description: 'Espace membres',
    },
  },
  {
    path: '/account/:tab',
    param_defaut: ['dashboard'],
    name: 'Mon compte',
    icon: 'fas fa-user-edit',
    component: () => import('@/views/Account'),
    meta: {
      requiresAuth: true,
      access: ['user', 'operator', 'admin', 'root'],
      title: 'Dashboard',
      keywords: 'Espace , membres',
      description: 'Espace membres',
    },
  },
  {
    path: '/admin/:tab',
    param_defaut: ['dashboard'],
    name: 'Administrateur',
    icon: 'fas fa-users-cog',
    component: () => import('@/views/Admin'),
    meta: {
      requiresAuth: true,
      access: ['operator', 'admin', 'root'],
      title: 'Dashboard',
      keywords: 'Espace , membres',
      description: 'Espace administrateur',
    },
  },
  {
    path: '/logout',
    name: 'Déconnexion',
    icon: 'fas fa-sign-out-alt',
    component: () => import('@/views/Logout'),
    meta: {
      requiresAuth: true,
      access: ['user', 'operator', 'admin', 'root'],
      title: 'Déconnexion',
      keywords: 'Espace , membres',
      description: 'Espace membres',
    },
  },
])

export default routes

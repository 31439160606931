import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(["listeners"]),
  },
  created() {
    this.$store.watch(
      (state) => this.listeners["bvToast"],
      (bvToast) => {
        const Obj = bvToast[0];
        setTimeout(() => {
          this.$bvToast.toast(Obj.msg || "", {
            title: Obj.title || "",
            variant: Obj.variant || "primary",
            solid: true,
            autoHideDelay: Obj.time || 2000,
            appendToast: true,
            toaster: "b-toaster-bottom-right",
          });
        }, Obj.timeout || 0);
      }
    );
  },
};

import router from '@/router';

export default {
  namespaced: true,
  state: () => ({
    // State only
    user: {
      id: -1,
      loggedIn: false,
      username: "",
      rank: "guest",
      firstName: "",
      lastName: "",
      email: "",
      created: "01-01-1970 00:00:00",
      last_connected: "01-01-1970 00:00:00",
      captchaPassed: false,
    },
    extraInfo: {
      gender: "male",
      firstName: "",
      lastName: "",
      phone: "",
      age: "2020-10-08",
      adress: "",
      citycode: "",
      city: "",
    },
    axios_data: {},
  }),
  getters: {},
  mutations: {
    user_account(state, payload) {
      state.user = payload;
    },
    extra_info(state, payload) {
      state.extraInfo = payload;
    },
    axios_data(state, payload) {
      state.axios_data = payload;
    },
  },
  actions: {
    user_account({ commit }, Obj) {
      commit("user_account", Obj);
    },
    extra_info({ commit }, Obj) {
      commit("extra_info", Obj);
    },
    axios_data({ commit }, Obj) {
      commit("axios_data", Obj);
    },
    async login({ state }, { vm, username, password, captcha }) {
      await vm.$root.axios("post", "api/auth/login", {
        username: username,
        password: password,
        captcha: captcha,
      });

      if (state.user.loggedIn) {
        const user = state.user;
        const extraInfo = state.extraInfo;

        this.dispatch("listeners/bvToast", {
          title: `Connexion réussie`,
          variant: "dark",
          msg: `Bienvenue dans votre espace ${extraInfo.firstName} ${extraInfo.lastName}, votre dernière connexion était le ${user.last_connected}`,
          time: 5000,
        });

        router.push("/account/dashboard");
      }
    },

    async register(
      { state },
      { vm, content, passwd1, passwd2, extra_user_info, captcha }
    ) {
      await vm.$root.axios("post", "api/auth/register", {
        username: content.username,
        set_password1: passwd1,
        set_password2: passwd2,
        rank: content.rank,
        email: content.email,

        gender: extra_user_info.gender,
        firstName: extra_user_info.firstName,
        lastName: extra_user_info.lastName,
        phone: extra_user_info.phone,
        age: extra_user_info.age,
        adress: extra_user_info.adress,
        citycode: extra_user_info.citycode,
        city: extra_user_info.city,

        captcha: captcha,
      });

      const axios_data = state.axios_data;

      if (axios_data && axios_data.auth_response === "account_created") {
        this.dispatch("listeners/bvToast", {
          title: `S\'enregistrer`,
          variant: "dark",
          msg:
            "Votre compte a été crée avec succès, consultez vos email pour activer votre compte",
          time: 30000,
          timeout: 150,
        });

        router.push("/account/login");
      }
    },

    async lostpassword({ state }, { vm, recover_email, captcha }) {
      await vm.$root.axios("post", "api/recovered/password", {
        email: recover_email,
        captcha: captcha,
      });

      const axios_data = state.axios_data;

      if (axios_data && axios_data.auth_response === "send_mailcode_password") {
        this.dispatch("listeners/bvToast", {
          title: `Mail envoyé`,
          variant: "info",
          msg: `Un mail a été envoyé à votre adresse email, contenant la démarche à suivre pour récupérer votre mot de passe, regardez également dans vos SPAM`,
          time: 30000,
          timeout: 150,
        });

        router.push("/account/login");
      }
    },

    async logout({}, { vm }) {
      await vm.$root.axios("delete", "api/auth/logout");

      localStorage.removeItem("jwt");
      localStorage.removeItem("user");
      localStorage.removeItem("extra_info");

      this.dispatch("listeners/bvToast", {
        title: `Déconnexion réussie`,
        variant: "dark",
        msg: "Vous avez été déconnecté, à bientot",
        time: 2000,
        timeout: 150,
      });

      router.push("/account/login");
    },
  },
};

import Vue from 'vue';

import Vuex from 'vuex';

import account from './modules/account';
import admin from './modules/admin.js';
import axios from './modules/axios';
import commons from './modules/commons';
import events from './modules/events.js';
import listeners from './modules/listeners.js';
import view_home from './modules/view_home.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { commons, events, axios, account, listeners, admin, view_home },
});

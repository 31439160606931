import "@/assets/vendor/css/buorg.css";

import Vue from "vue";

import VueBrowserUpdate from "vue-browserupdate";

// Browsers
// Key	Browser	Note
// e	Edge	Falls back to value specified in "i" when omitted
// i	Internet Explorer	Falls back to value specified in "e" when omitted
// c	Chrome
// f	Firefox
// o	Opera
// o_a	Opera (Android)
// s	Safari
// y	Yandex Browser
// v	Vivaldi
// uc	UC Browser
// ios	iOS browser	Falls back to "s" when omitted
// samsung	Samsung Internet (Android)
// required:{
// e: 84,
// f: 78,
// o: 69,
// o_a: 59,
// s: 13.5,
// c: "84.0.4147.89",
// y: 20.7,
// v: "3.1",
// uc: 13.0,
// samsung: 11.2,
// }
Vue.use(VueBrowserUpdate, {
  options: {
    required: {
      e: -1,
      f: -1,
      o: -1,
      o_a: -1,
      s: 14.0,
      c: -1,
      y: -1,
      v: -1,
      uc: -1,
      samsung: -1
    },
    insecure: true,
    unsupported: true,
    api: 2020.07,
    container: document.body,
    no_permanent_hide: false,
    noclose: false,
    newwindow: true,
    test: false,
    l: false,
    reminder: 0
  }
});

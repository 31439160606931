const allowed_show_message = [
  "user_cant_empty",
  "user_already_exist",
  "email_not_valid",
  "account_disabled",
  "login_failed",
  "passwords_dont_match",
  "password_too_short",
  "one_uppercase_required",
  "two_lowercase_required",
  "two_digits_required",
  "bad_password",
  "empty_password",
  "unknown_email_address",
  "email_code_already_sent",
  "captcha_failed",
  "no_permissions",
  "no_permissions_to_change_rank",
  "no_permissions_to_change_your_rank",
  "spam_prevention",
  "horse_name_not_valid",
  "horse_ueln_not_valid",
  "horse_sire_not_valid",
  "horse_race_not_valid",
  "something_is_wrong",
  "name_too_long",
  "phone_invalid",
  "message_too_long",
  "email_already_exist",
  "email_cant_empty",
];

const message_decode = {
  user_cant_empty: "l'utilisateur ne peut pas être vide !",
  user_already_exist: "L'utilisateur existe déja",
  email_not_valid: "E-Mail invalide",
  account_disabled: "Le compte semble désactivé",
  login_failed: "Erreur d'identifiants",
  passwords_dont_match: "Les mots de passe ne correspondent pas",
  password_too_short: "Le mot de passe est trop court",
  one_uppercase_required: "Le mot de passe doit contenir au moins 1 majuscule",
  two_lowercase_required: "Le mot de passe doit contenir au moins 2 minuscules",
  two_digits_required: "Le mot de passe doit contenir au moins 2 chiffres",
  captcha_not_valid: "Veuillez renseigner le captcha",
  captcha_failed: "Vous avez mal renseigné le captcha",
  bad_password: "Mot de passe erronné",
  empty_password: "Mot de passe vide",
  unknown_email_address: "Adresse email inconnu",
  email_code_already_sent: "Code déja envoyé par email (regardez vos spam)",
  no_permissions: "Vous n'avez pas la permission",
  no_permissions_to_change_rank:
    "Vous n'avez pas la permission de changer le rang",
  no_permissions_to_change_your_rank:
    "Vous n'avez pas la permission de changer votre propre rang",
  spam_prevention:
    "Afin de lutter contre le spam, veuillez patienter 90 secondes au total puis réessayez",
  horse_name_not_valid: "Nom du cheval invalide ou deja utilisé",
  horse_ueln_not_valid: "UELN trop court ou trop long ou invalide",
  horse_sire_not_valid: "SIRE trop court ou trop long ou invalide",
  horse_race_not_valid: "Race invalide",
  something_is_wrong: "Quelque chose ne va pas ...",
  name_too_long: "Nom trop long ...",
  phone_invalid: "Téléphone invalide ...",
  message_too_long: "Message trop long ...",
  email_already_exist: "L'email existe déja",
  email_cant_empty: "L'email ne peut pas etre vide",
};

export default {
  methods: {
    show_toast_check_input(check_input) {
      if (check_input) {
        for (let i = 0; i < check_input.length; i++) {
          if (allowed_show_message.indexOf(check_input[i]) !== -1) {
            this.$root.showToast(
              `Erreur`,
              message_decode[check_input[i]],
              "danger",
              10000
            );
          }
        }
      }
    },
    showToast(
      title = "",
      msg = "",
      variant = "info",
      time = 2000,
      timeout = 0
    ) {
      setTimeout(() => {
        this.$bvToast.toast(msg, {
          title: title,
          variant: variant,
          solid: true,
          autoHideDelay: time,
          appendToast: true,
          toaster: "b-toaster-bottom-right",
        });
      }, timeout);
    },
    internalError() {
      this.$root.showToast(
        `Erreur interne`,
        `Une erreur sur la page vient de se produire ... nous vous invitons à recommencer dans quelques instants`,
        "danger",
        15000
      );
    },
  },
};

import __Axios from 'axios'
import Qs from 'qs'
import { mapState } from 'vuex'

const axios_instance = __Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000,
  responseType: 'json',
  responseEncoding: 'utf8',
  'Content-Type': 'multipart/form-data',
  xsrfCookieName: process.env.VUE_APP_API_CSRF_COOKIE,
  xsrfHeaderName: 'csrf-token',
})

const HTTP = {
  computed: {
    ...mapState(['axios']),
  },
  methods: {
    async responseData(response) {
      if (response) {
        this.$store.dispatch('axios/console_debug', response)

        if (response['headers'] && response['headers']['content-length']) {
          this.$store.dispatch(
            'axios/content_length',
            response['headers']['content-length']
          )
        }
        if (response['data']) {
          this.axiosData(response['data'])
          return response['data']
        } else {
          return false
        }
      } else return false
    },
    async getData(url = '', params = {}, compareVersion = false) {
      params.token = localStorage.getItem('jwt')

      if (compareVersion) {
        params.hash_version = localStorage.getItem('hash_version')
      }

      try {
        const response = await axios_instance.get(url, {
          params: params,
        })
        this.$store.dispatch('axios/first_network_error', false)

        this.$store.dispatch('axios/http_reading', false)
        return this.responseData(response)
      } catch (error) {
        if (!this.axios.firstNetworkError) {
          this.$store.dispatch('axios/first_network_error', true)
          this.$store.dispatch('axios/http_reading', true)
        }
        this.$store.dispatch('axios/perm_exec_time', 'na')
        console.error(error)
      }
    },
    async postData(url = '', params = {}) {
      this.$store.dispatch('axios/http_busy', true)
      params.token = localStorage.getItem('jwt')

      try {
        const response = await axios_instance.post(url, Qs.stringify(params))
        this.$store.dispatch('axios/http_busy', false)
        return this.responseData(response)
      } catch (error) {
        console.error(error)
      }
    },
    async changeData(url = '', params = {}) {
      this.$store.dispatch('axios/http_busy', true)
      params.token = localStorage.getItem('jwt')

      try {
        const response = await axios_instance.put(url, Qs.stringify(params))
        this.$store.dispatch('axios/http_busy', false)
        return this.responseData(response)
      } catch (error) {
        console.error(error)
      }
    },
    async deleteData(url = '', params = {}) {
      this.$store.dispatch('axios/http_busy', true)
      params.token = localStorage.getItem('jwt')

      try {
        const response = await axios_instance.delete(url, {
          data: Qs.stringify(params),
        })
        this.$store.dispatch('axios/http_busy', false)
        return this.responseData(response)
      } catch (error) {
        console.error(error)
      }
    },
  },
}

export default HTTP

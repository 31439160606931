<template>
  <div id="app" class="backgroundWebsiteColor1 text-light">
    <isBusy />
    <localStorageRgpd onlyAccept />
    <Navbar v-if="!isHomeRoute" />
    <ScrollProgressBar />
    <transition name="smoothly-transition" mode="out-in">
      <router-view />
    </transition>
    <Navbar v-if="isHomeRoute" />
    <Footer v-if="!isHomeRoute" />
    <Debug v-if="debug && !isHomeRoute" />
  </div>
</template>

<script>
import "./assets/css/custom.css";
import "./assets/css/typography.css";
import "./assets/css/smoothly-transition.css";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    Footer: () => import("./components/Footer"),
    isBusy: () => import("./components/isBusy"),
    ScrollProgressBar: () => import("./components/ScrollProgressBar"),
    Navbar: () => import("./components/Navbar"),
    localStorageRgpd: () => import("./components/localStorageRgpd"),
    Debug: () => import("./components/Debug"),
  },
  computed: {
    ...mapState(["events"]),
    isHomeRoute() {
      return this.$route.path === "/" ? true : false;
    },
    debug() {
      return window.location.hostname === "127.0.0.1" ? true : false;
    },
  },
  beforeCreate() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        this.$store.dispatch("axios/start_busy", false);
      }
    };
  },
  mounted() {
    this.$store.dispatch("axios/http_reading", true);
    // this.$root.axios("get", "api/token/get/&&/api/personnal/info");

    this.$root.handleGetRequest("api/hello", false);
    this.$root.handleGetRequest("api/token/get", false);
    this.$root.handleGetRequest("api/personnal/info", true);

    setTimeout(() => {
      this.$store.dispatch("axios/start_busy", false);
    }, 5000);
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
  transition: 1s;
}
body {
  background: #343a40;
  color: white;
  /* transition: 1s; */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  transition: 1s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.backgroundWebsiteColor1,
.bg-blue {
  background: #163a58;
}
.text-blue {
  color: #163a58;
}
.variant-blue {
  background: #163a58;
  color: white;
}
</style>


export default {
  namespaced: true,
  state: () => ({
    tabsAllow: [],
    actionsAllow: [],
  }),
  getters: {
    allow_this_tab: (state) => (tab) => {
      return state.tabsAllow.indexOf(tab) === -1 ? false : true;
    },
    allow_this_action: (state) => (action) => {
      return state.actionsAllow.indexOf(action) === -1 ? false : true;
    },
  },
  mutations: {
    allow_this_tab(state, payload) {
      state.tabsAllow = payload;
    },
    allow_this_action(state, payload) {
      state.actionsAllow = payload;
    },
  },
  actions: {
    async save_admin_rights({ commit }, { vm }) {
      this.dispatch("axios/http_busy", false);
      await vm.$root.handleGetRequest("api/token/get", false);
      const data = await vm.$root.handleGetRequest(
        "admin/what/are/my/rights",
        true
      );

      const rights = data && data["admin_what_are_my_rights"];

      commit(
        "allow_this_tab",
        rights && rights["tabs"] ? JSON.parse(rights["tabs"]) : []
      );
      commit(
        "allow_this_action",
        rights && rights["actions"] ? JSON.parse(rights["actions"]) : []
      );
    },
  },
};
